import { useQuery } from 'react-query/react'
import useAxios from './useAxios'

import { useMemo } from 'react'
import { LandingPageV2 } from 'types/landingPageV2'
import {
  ProductPackage,
  ProductPackagesSection as ProductPackagesSectionType,
  ProductPage,
} from '../types/productPageV2'
import useDiscountToken from './useDiscountToken'
import { getOriginalSKU, useAllSKUs } from './useSKUs'
import useUser from './useUser'

/**
 * useCheckoutEvent is a hook which POSTs to the `v2/leads/checkout` endpoint
 * when user is logged in and views the 'resume/cv-writing' page.
 * It passes in the following query params:
 * event: "Checkout Page Viewed"
 * p: A plan code string for the packages displayed on the page (Eg: "p=iFbBA45,ZT5KxC4,CSexzxm")
 * dt?: The discount token if any
 */
export function useCheckoutEvent(landingPage: LandingPageV2 | ProductPage) {
  const axios = useAxios()
  const skus = useAllSKUs()
  const discountToken = useDiscountToken()
  const { lead_auth_token: leadAuthToken } = useUser()

  const productPackageRows = useMemo(
    () =>
      (landingPage.rows as ProductPackagesSectionType[])
        .filter((row) =>
          row.__typename.startsWith('ContentfulProductPackagesSection')
        )
        .flatMap((row) => row?.productPackageRows ?? []),
    [landingPage.rows]
  )

  let productPackage: ProductPackage | undefined

  if (landingPage.__typename === 'ContentfulProductPage') {
    productPackage = landingPage.product?.productPackage
  }

  // Extract the item keys and sort values, if any, for the packages displayed on the page
  const packageItemKeyInfo = useMemo(
    () =>
      productPackage
        ? [
            {
              items: productPackage?.items,
              sort: productPackage?.sort,
              planCode: productPackage?.planCode,
            },
          ]
        : productPackageRows?.map((packageRow) => ({
            items: packageRow.productPackage?.items,
            sort: packageRow.productPackage?.sort,
            planCode: packageRow.productPackage?.planCode,
          })),
    [productPackageRows, productPackage]
  )

  const planCodes = useMemo(
    () =>
      packageItemKeyInfo
        ?.map(
          (singlePackage) =>
            getOriginalSKU(
              skus,
              singlePackage.items,
              singlePackage.planCode,
              singlePackage.sort
            )?.plan_code
        )
        ?.filter(Boolean),
    [packageItemKeyInfo, skus]
  )

  let url = 'api/v2/leads/checkout?event=Checkout Page Viewed'

  // NOTE: Making sure we have unique planCodes
  url += `&p=${[...new Set(planCodes)].join(',')}`

  if (discountToken) {
    url += `&dt=${discountToken}`
  }

  return useQuery(
    ['checkoutEvent', leadAuthToken, url, planCodes],
    async () => axios.post(url),
    {
      enabled:
        !!leadAuthToken && planCodes instanceof Array && planCodes.length > 0,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    }
  )
}

export function usePurchasePageEvent(args: {
  planCodes: string[]
  discountToken: string | null
}) {
  const { lead_auth_token: leadAuthToken } = useUser()
  const axios = useAxios()

  let url = 'api/v2/leads/checkout?event=Purchase Page Viewed'

  // NOTE: Making sure we have unique planCodes
  url += `&p=${[...new Set(args.planCodes)].join(',')}`

  if (args.discountToken) {
    url += `&dt=${args.discountToken}`
  }

  return useQuery(
    ['checkoutEvent', leadAuthToken, url, args.planCodes],
    async () => axios.post(url),
    {
      enabled:
        !!leadAuthToken &&
        args.planCodes instanceof Array &&
        args.planCodes.length > 0,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    }
  )
}
